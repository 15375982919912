<template>
    <div class="new-event-form fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50 overflow-auto" @click.self= "closeComponent">
       <div class="bg-white rounded-xl shadow-md w-11/12 md:w-3/5 lg:w-2/5 h-auto max-h-[97vh] max-w-[450px] overflow-auto">
         
         <!-- Cross button
         <div class="sticky top-2 mr-2 flex justify-end z-10">
             <button @click="closeComponent" class="border-black">
                 <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <path d="M1 1.01562L11 11.0156M1 11.0156L11 1.01562" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                 </svg>
             </button>
         </div> -->
 
         <!-- Content container -->
         <div class="w-full flex flex-col items-center border"> 
            <div class="font-semibold text-[18px] text-center mt-9 mb-4 px-2 md:px-0"> You are currently using Zippie as {{ displayCurrentRole }}</div>

            <div class="relative text-[15px] text-center text-[#6B7280] w-[85%]">
                Do you want to switch to the {{ displayNextRole }} view?
            </div> 
    
            <!-- Buttons -->   
            <div class="flex mb-9 mt-8 w-full px-2 md:px-0 justify-center">
                <button @click="emitRoleSwitch" class="bg-black text-white py-2 md:py-2 px-2 w-[170px] text-black rounded-lg cursor-pointer hover:bg-gray-800 block ml-2 mr-2" style="border: 1px solid #cbd5e1;">Yes</button>
                <button @click="closeComponent" class="bg-white text-black py-2 md:py-2 px-2 w-[170px] text-black rounded-lg cursor-pointer hover:bg-gray-200 block ml-2 mr-2" style="border: 1px solid #cbd5e1;">No</button>
            </div>
        </div>    
         
      </div>
     </div>    
 </template>



<script setup>

import { defineEmits,computed } from 'vue';


const emit = defineEmits(['close','switch-role']);


const closeComponent = () => {
        emit('close');
    };  


// const nextRole = computed(() => {
//   return props.currentRole === 'Parent' ? 'Guest Facilitator' : 'Parent';
// });

const nextRole = computed(() => {
  if (props.currentRole === 'Parent') {
    // Prioritized order of roles to switch back to
    const priorityRoles = ['Guest Facilitator','System Manager', 'Educator',];
    
    // Find the highest priority role that the user has
    for (const role of priorityRoles) {
      if (props.userRoles.includes(role)) {
        return role;
      }
    }
    // If no other role found, default to 'Guest Facilitator'
    return 'Guest Facilitator';
  } else {
    // If current role is not 'Parent', switch to 'Parent'
    return 'Parent';
  }
});


// Added this to Render 'Educator' for 'Guest Facilitator' without changing existing funtions 
const displayNextRole = computed(() => {
  return nextRole.value === 'Guest Facilitator' ? 'Educator' : nextRole.value;
});

const displayCurrentRole = computed(() => {
  return props.currentRole === 'Guest Facilitator' || props.currentRole === 'Educator' ? 'an Educator' : `a ${props.currentRole}`;
});


const emitRoleSwitch = () => {
  emit('switch-role', nextRole.value);
}

const props = defineProps({
  currentRole: {
      type: String,
      required: true,
    },
    userRoles: {
      type: Array,
      required: true,
    },
  });

</script>    