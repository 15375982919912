<template>
    <div class="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50 overflow-auto" @click.self="closeComponent">
      <div class="bg-white rounded-xl shadow-md w-11/12 md:w-3/5 lg:w-2/5 h-auto max-h-[97%] max-w-[450px] overflow-auto">
        <!-- Cross button -->
        <div class="sticky top-6 mr-5 flex justify-end z-10">
          <button @click="closeComponent" class="border-black">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1.01562L11 11.0156M1 11.0156L11 1.01562" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>
  
        <!-- Content container -->
        <div class="w-full flex flex-col px-4">
          <div class="font-semibold text-[22px] mt-2 mb-6 px-2 md:px-0">
           How to Install zippie?
          </div>
  
          <div class="relative mb-8">
            <div v-if="currentStep === 1">
              <div class="font-semibold text-[19px] mb-4 px-2 md:px-0">
                Step 1
              </div>
              <div class="font-semibold text-[15px] mt-4 mb-4 px-2 md:px-0">
                Click on the 3 dots on your mobile Chrome browser
              </div>
              <div v-if="!imageLoaded[0]" class="loading-indicator flex justify-center">
                <div class="animate-spin inline-block size-6 border-[3px] border-current border-t-transparent text-[#FFD700] rounded-full " role="status" aria-label="loading">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              
              <img v-show="imageLoaded[0]" @load="onImageLoad(0)" src="https://comini-assets.s3.ap-south-1.amazonaws.com/install-zippie-pwa-popup-images/pwa-Install-step-1.png" height="" width=""/>

            </div>
            <div v-else-if="currentStep === 2">
              <div class="font-semibold text-[19px] mb-4 px-2 md:px-0">
                Step 2
              </div>
              <div class="font-semibold text-[15px] mt-4 mb-4 px-2 md:px-0">
                Click on "Add to Home screen" at the bottom of the menu
              </div>
              <div v-if="!imageLoaded[1]" class="loading-indicator flex justify-center">
                <div class="animate-spin inline-block size-6 border-[3px] border-current border-t-transparent text-[#FFD700] rounded-full " role="status" aria-label="loading">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              
              <img v-show="imageLoaded[1]" @load="onImageLoad(1)" src="https://comini-assets.s3.ap-south-1.amazonaws.com/install-zippie-pwa-popup-images/pwa-Install-step-2.png" height="" width=""/>

            </div>
            <div v-else-if="currentStep === 3">
              <div class="font-semibold text-[19px] mb-4 px-2 md:px-0">
                Step 3
              </div>
              <div class="font-semibold text-[15px] mt-4 mb-4 px-2 md:px-0">
                Click on the "Install" option.
              </div>
              <div v-if="!imageLoaded[2]" class="loading-indicator flex justify-center">
                <div class="animate-spin inline-block size-6 border-[3px] border-current border-t-transparent text-[#FFD700] rounded-full " role="status" aria-label="loading">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              
              <img v-show="imageLoaded[2]" @load="onImageLoad(2)" src="https://comini-assets.s3.ap-south-1.amazonaws.com/install-zippie-pwa-popup-images/pwa-install-step-3.png" height="" width=""/>
                                                          
            </div>
            <div v-else-if="currentStep === 4">
              <div class="font-semibold text-[19px] mb-4 px-2 md:px-0">
                Step 4
              </div>
              <div class="font-semibold text-[15px] mt-4 mb-4 px-2 md:px-0">
                Locate the Zippie icon on your phone and click on it to launch the app.
              </div>
              <div v-if="!imageLoaded[3]" class="loading-indicator flex justify-center">
                <div class="animate-spin inline-block size-6 border-[3px] border-current border-t-transparent text-[#FFD700] rounded-full " role="status" aria-label="loading">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              
              <img v-show="imageLoaded[3]" @load="onImageLoad(3)" src="https://comini-assets.s3.ap-south-1.amazonaws.com/install-zippie-pwa-popup-images/pwa-install-step-4.png" height="" width=""/>
                         
            </div>
          </div>

           <!-- Progress bar -->
           <div class="flex space-x-2 mb-8">
            <div v-for="i in 4" :key="i" class="self-center" :class="['w-[33px] h-2', currentStep >= i ? 'bg-[#FFD700]' : 'bg-gray-200']"></div>
            <div class="text-[13px] font-medium"> {{currentStep}} /4 </div>
          </div>
  
          <!-- Buttons -->
          <div class="flex mb-6 w-full px-2 md:px-0 justify-center">
            <button @click="prevStep" :disabled="currentStep === 1" class="bg-white text-black py-2.5 px-2 w-[135px] text-[14px] text-black rounded-lg hover:bg-gray-200 block ml-2 mr-2" :class="{'opacity-50 cursor-not-allowed': currentStep === 1}" style="border: 1px solid #cbd5e1;">Back</button>
            <button @click="nextStep" class="py-2.5 px-2 w-[135px] rounded-lg cursor-pointer bg-black text-white text-[14px] hover:bg-opacity-80 block ml-2 mr-2" style="border: 1px solid #cbd5e1;">
              {{ currentStep === 4 ? 'Got it!' : 'Next' }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref } from 'vue';
  
  const emit = defineEmits(['close']);
  
  const currentStep = ref(1);
  
  const closeComponent = () => {
    emit('close');
  };
  
  const nextStep = () => {
    if (currentStep.value < 4) {
      currentStep.value++;
    } else {
      // Handle form completion
      emit('close');
    }
  };
  
  const prevStep = () => {
    if (currentStep.value > 1) {
      currentStep.value--;
    }
  };


const imageLoaded = ref([false, false, false, false]);

const onImageLoad = (index) => {
  imageLoaded.value[index] = true;
};

  </script>