import { createApp, inject, reactive } from "vue";
import App from "./App.vue";
import './style.css';
import "preline/preline";

import router from './router';
import resourceManager from "../../../doppio/libs/resourceManager";
import call from "../../../doppio/libs/controllers/call";
// import socket from "../../../doppio/libs/controllers/socket";
import Auth from "../../../doppio/libs/controllers/auth";
// import Vue from 'vue';
import * as Sentry from "@sentry/vue";``

import {
  Button,
  Card,
  Input,
  setConfig,
  frappeRequest,
  resourcesPlugin,
} from 'frappe-ui'
import PrivacyPolicy from "./views/PrivacyPolicy.vue";

const app = createApp(App);
const auth = reactive(new Auth());

 
setConfig('resourceFetcher', frappeRequest)

// Plugins

app.use(resourceManager);

app.use(resourcesPlugin)
// Global Properties,
// components can inject this
app.provide("$auth", auth);
app.provide("$call", call);
// app.provide("$socket", socket);



// User agreement check
   const userAgreementStatus = reactive({ accepted: false });

	// Route guard
	router.beforeEach(async (to, from, next) => {
		const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
	
		if (requiresAuth) {
		if (!auth.isLoggedIn) {
			window.localStorage.setItem('redirect_after_login', window.location.href);
			next({ name: 'UserLogin', query: { route: to.path } });
		} 
		// This executes when the app first loads or when user has not accepted the terms of agreement
		else if (!userAgreementStatus.accepted) {
			const response = await call('curriculum_planner.curriculum_planner.doctype.user_agreement_acceptance.api.check_user_agreement_acceptance');
			console.log("R: User agreement status is not accepted Or the App has just loaded, made a call to the backend api to chech acceptance status")
			
			if (response.status === 'success' && response.terms_and_privacy_accepted ) {
				console.log ("R:terms and privacy accepted returned True from the api call response!!")
                userAgreementStatus.accepted = true;
				 
				//redirect guest facilitator back to observation
				if (localStorage.getItem('roleChangedTo') === 'Guest Facilitator') {
					console.log("R: rad from local storage, guest facilitator is true, redirecting to observations")
					if (to.path.startsWith('/front-portal/observations/view')) {
					  next();
					} 
					else if (to.path !== '/front-portal/observations') {
					  next({ path: '/front-portal/observations' });
					} 
					else {
					  next();
					}
				  } else {
					next();
				  }

			 next();
			}
			
			else if (response.status === 'success' && !response.terms_and_privacy_accepted) {
			    // Redirect to login page with a query parameter to show terms
				console.log("R:user agreement NOT accepted, redirecting to login from gaurd funtion with query params")
				next({ name: 'Login', query: { terms_of_use_accepted: response.terms_of_use_accepted, privacy_policy_accepted:response.privacy_policy_accepted } });
				
			}
			else {
				// Redirect to login page in case of an error
				console.log("R:user agreement error, redirecting to login without query params")
				next({ name: 'Login'});
			}
		 } 

		// This executes before each route when the user has accepted the tems of agreement
		else if(userAgreementStatus.accepted) {
		console.log("R: before each rerouting")
			//redirect guest facilitator back to observation
			if (localStorage.getItem('roleChangedTo') === 'Guest Facilitator') {
				console.log("R: Guest facilitator is true, redirecting to observations")
				if (to.path.startsWith('/front-portal/observations/view')) {
				  next();
				} 
				else if (to.path !== '/front-portal/observations') {
				  next({ path: '/front-portal/observations' });
				} 
				else {
				  next();
				}
			  } else {
				next();
			  }
		}
	   } 
	   else {
		next();
	   }
	});


Sentry.init({
	app,
	dsn: "https://5b1f012dcbdce445eb8b2839308bc678@o4507921916362752.ingest.us.sentry.io/4507921921015808",
	integrations: [
		Sentry.browserTracingIntegration({ router }),
		Sentry.replayIntegration(),
	],
	// Tracing
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});

if ('serviceWorker' in navigator) {
	navigator.serviceWorker.register('/firebase-messaging-sw.js', { scope: "/firebase-cloud-messaging-push-scope" })
		.then(function(registration) {
		console.log('Firebase Service Worker registered with scope:', registration.scope);
		}).catch(function(error) {
		console.log('Firebase Service Worker registration failed:', error);
		});
}

// document.addEventListener('visibilitychange', function() {
// 	if (document.hidden) {
// 	  console.log('Page is now hidden (simulating background state)');
// 	  // You can trigger your test notification here
// 	} else {
// 	  console.log('Page is now visible');
// 	}
//   });
  
app.use(router);

app.mount("#app");

