<!-- For Admin and Educator -->
<template>
  <div class="md:hidden">
    <!-- Preline Sidebar -->
    <div id="hs-offcanvas-example-admin" ref="sidebarModalRef" class="hs-overlay [--auto-close:lg] hs-overlay-open:translate-x-0 -translate-x-full transition-all duration-300 transform hidden fixed top-0 start-0 bottom-0 z-[60] w-64 bg-[#FEF3C2] border-e border-gray-200 pt-7 pb-10 overflow-y-auto lg:block lg:translate-x-0 lg:end-auto lg:bottom-0 [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500 dark:bg-neutral-800 dark:border-neutral-700" role="dialog" tabindex="-1" aria-label="Sidebar">
      <!--Heading-->
      <div class="ml-3">
        <div class="flex-none font-semibold text-xl text-black focus:outline-none focus:opacity-80 dark:text-white" aria-label="Brand">Menu</div>
      </div>
      <nav class="hs-accordion-group py-6 w-full flex flex-col flex-wrap" data-hs-accordion-always-open>
        <ul class="space-y-1.5 ">

          <!-- Switch user role view -->
          <div v-if="userDetails.user_roles.includes('Parent') && userDetails.user_roles.length > 1"  class="w-full flex flex-row max-w-[100%] focus:outline-none items-center pl-2.5">
            <!-- Button -->
            <div> View as</div> 
            <button @click="handleRoleSwitchConfirmationModal" class="text-white bg-black rounded w-auto self-center text-center text-xs py-3 px-3 ml-4 hover:cursor-pointer">{{ nextRole}} </button>
          </div>

          <!-- Dashboard -->  
          <router-link to="/front-portal/educator-dashboard" @click="closeModal">
            <li >
                <div class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-yellow-800 focus:bg-gray-100">
                Dashboard
                </div>
            </li>
           </router-link> 

           <!-- Schedule -- Calendar, Planweek -->
          <li class="hs-accordion" id="account-accordion">
            <button type="button" class="hs-accordion-toggle hs-accordion-active:hover:bg-transparent hs-accordion-active:bg-[] w-full text-start flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-gray-100 focus:outline-none focus:bg-gray-100" aria-expanded="true" aria-controls="account-accordion">
              Schedule

              <svg class="hs-accordion-active:block ms-auto hidden size-4 text-gray-600 group-hover:text-gray-500 dark:text-neutral-400" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m18 15-6-6-6 6"/></svg>

              <svg class="hs-accordion-active:hidden ms-auto block size-4 text-gray-600 group-hover:text-gray-500 dark:text-neutral-400" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m6 9 6 6 6-6"/></svg>
            </button>

            <div id="account-accordion" class="hs-accordion-content w-full overflow-hidden transition-[height] duration-300 hidden" role="region" aria-labelledby="account-accordion">
              <ul class="pt-2 ps-2 bg-[#FDD835]">

                <!-- calendar -->
                <router-link to="/front-portal/calendar" @click="closeModal">
                    <li>
                    <a class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:bg-neutral-800 dark:text-neutral-400 dark:hover:text-neutral-300 dark:focus:text-neutral-300" href="#">
                        Calendar
                    </a>
                    </li>
                </router-link> 

                <!-- Plan Week -->
                 <template v-if="userDetails.is_system_manager || userDetails.user_roles.includes('Educator')">
                    <router-link to="/front-portal/calendar/plan-week" @click="closeModal">
                        <li>
                        <a class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:bg-neutral-800 dark:text-neutral-400 dark:hover:text-neutral-300 dark:focus:text-neutral-300" href="#">
                            Plan Week
                        </a>
                        </li>
                    </router-link> 
                  </template>

                   <!-- Lunch Menu -->
                    <router-link to="/front-portal/lunch-menu" @click="closeModal">
                        <li>
                        <a class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:bg-neutral-800 dark:text-neutral-400 dark:hover:text-neutral-300 dark:focus:text-neutral-300" href="#">
                            Lunch Menu
                        </a>
                        </li>
                    </router-link>
                
              </ul>
            </div>
          </li>

          <!-- Observations --> 
          <template v-if="userDetails.is_system_manager || userDetails.user_roles.includes('Educator')">      
            <router-link to="/front-portal/observations" @click="closeModal">
              <li>
                  <div class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-gray-100 dark:hover:bg-neutral-700 dark:text-neutral-400 dark:hover:text-neutral-300 focus:bg-gray-100">
                  Observations
                  </div>
              </li>
            </router-link> 
          </template>  

           <!-- Activities --> 
           <template v-if="userDetails.is_system_manager || userDetails.user_roles.includes('Educator')">      
              <router-link to="/front-portal/activities" @click="closeModal">
                <li>
                    <div class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-gray-100 dark:hover:bg-neutral-700 dark:text-neutral-400 dark:hover:text-neutral-300">
                    Activities
                    </div>
                </li>
              </router-link> 
           </template>  

           <!-- Worksheets -->  
           <router-link to="/front-portal/worksheets" @click="closeModal">
            <li>
                <div class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-gray-100 dark:hover:bg-neutral-700 dark:text-neutral-400 dark:hover:text-neutral-300">
                Worksheets
                </div>
            </li>
           </router-link> 

          <!-- Learners -- Learners profiles, Learner Reports, Photo Essays -->
          <li class="hs-accordion" id="account-accordion">
            <button type="button" class="hs-accordion-toggle hs-accordion-active:text- hs-accordion-active:hover:bg-transparent w-full text-start flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:bg-neutral-800 dark:text-neutral-400 dark:hs-accordion-active:text-white dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 dark:focus:text-neutral-300" aria-expanded="true" aria-controls="account-accordion">
              Learners

              <svg class="hs-accordion-active:block ms-auto hidden size-4 text-gray-600 group-hover:text-gray-500 dark:text-neutral-400" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m18 15-6-6-6 6"/></svg>

              <svg class="hs-accordion-active:hidden ms-auto block size-4 text-gray-600 group-hover:text-gray-500 dark:text-neutral-400" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m6 9 6 6 6-6"/></svg>
            </button>

            <div id="account-accordion" class="hs-accordion-content w-full overflow-hidden transition-[height] duration-300 hidden" role="region" aria-labelledby="account-accordion">
              <ul class="pt-2 ps-2 bg-[#FDD835]">


                <!-- Learner Reports  -->
                <router-link to="/front-portal/observations/report" @click="closeModal">
                    <li>
                    <a class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:bg-neutral-800 dark:text-neutral-400 dark:hover:text-neutral-300 dark:focus:text-neutral-300" href="#">
                      Learner Reports 
                    </a>
                    </li>
                </router-link> 

                <!-- Photo Essays  -->
                <router-link to="/front-portal/photo-essays" @click="closeModal">
                    <li>
                    <a class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:bg-neutral-800 dark:text-neutral-400 dark:hover:text-neutral-300 dark:focus:text-neutral-300" href="#">
                      Photo Essays
                    </a>
                    </li>
                </router-link> 
                
              </ul>
            </div>
          </li>

          <!-- Educators -->  
          <!-- <router-link to="/front-portal/" @click="closeModal">
            <li>
                <div class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-gray-100 dark:hover:bg-neutral-700 dark:text-neutral-400 dark:hover:text-neutral-300">
                Educators
                </div>
            </li>
           </router-link>  -->

           <!-- Resources -->  
           <router-link to="/front-portal/resources" @click="closeModal">
            <li>
                <div class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-gray-100 dark:hover:bg-neutral-700 dark:text-neutral-400 dark:hover:text-neutral-300">
                Resources
                </div>
            </li>
           </router-link> 

           <!-- Micro Learning -->  
           <router-link to="/front-portal/educators" @click="closeModal">
            <li>
                <div class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-gray-100 dark:hover:bg-neutral-700 dark:text-neutral-400 dark:hover:text-neutral-300">
                Micro Learning
                </div>
            </li>
           </router-link> 


          <!-- Curriculum landscape -- Map, Table view -->
          <li class="hs-accordion" id="account-accordion">
            <button type="button" class="hs-accordion-toggle hs-accordion-active:text- hs-accordion-active:hover:bg-transparent w-full text-start flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-gray-100 focus:outline-none focus:bg-gray-100" aria-expanded="true" aria-controls="account-accordion">
             Curriculum Landscape

              <svg class="hs-accordion-active:block ms-auto hidden size-4 text-gray-600 group-hover:text-gray-500 dark:text-neutral-400" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m18 15-6-6-6 6"/></svg>

              <svg class="hs-accordion-active:hidden ms-auto block size-4 text-gray-600 group-hover:text-gray-500 dark:text-neutral-400" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m6 9 6 6 6-6"/></svg>
            </button>

            <div id="account-accordion" class="hs-accordion-content w-full overflow-hidden transition-[height] duration-300 hidden" role="region" aria-labelledby="account-accordion">
              <ul class="pt-2 bg-[#FDD835]">

                <!-- Map-->
                <router-link to="/front-portal/curriculum-landscape-map" @click="closeModal">
                    <li>
                      <a class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:bg-neutral-800 dark:text-neutral-400 dark:hover:text-neutral-300 dark:focus:text-neutral-300" href="#">
                          Landscape Map
                      </a>
                    </li>
                </router-link> 

                <!-- Table  -->
                <!-- <router-link to="" @click="closeModal">
                    <li>
                    <a class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:bg-neutral-800 dark:text-neutral-400 dark:hover:text-neutral-300 dark:focus:text-neutral-300" href="#">
                      Landscape Table
                    </a>
                    </li>
                </router-link>  -->
                
              </ul>
            </div>
          </li>


          <!-- More -->
          <li class="hs-accordion" id="account-accordion">
            <button type="button" class="hs-accordion-toggle hs-accordion-active: hs-accordion-active:hover:bg-yellow-800 w-full text-start flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-gray-100 focus:outline-none focus:bg-gray-100" aria-expanded="true" aria-controls="account-accordion">
             More

              <svg class="hs-accordion-active:block ms-auto hidden size-4 text-gray-600 group-hover:text-gray-500 dark:text-neutral-400" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m18 15-6-6-6 6"/></svg>

              <svg class="hs-accordion-active:hidden ms-auto block size-4 text-gray-600 group-hover:text-gray-500 dark:text-neutral-400" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m6 9 6 6 6-6"/></svg>
            </button>

            <div id="account-accordion" class="hs-accordion-content w-full overflow-hidden transition-[height] duration-300 hidden" role="region" aria-labelledby="account-accordion">
              <ul class="pt-2 bg-[#FDD835] pb-3">

                <!-- Events -->
                <!-- <li><a class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-gray-100 dark:hover:bg-neutral-700 dark:text-neutral-400 dark:hover:text-neutral-300" href="#">
                  Events
                </a></li> -->

                <!-- About us-->
                <!-- <li><a class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-gray-100 dark:hover:bg-neutral-700 dark:text-neutral-400 dark:hover:text-neutral-300" href="#">
                  About Us
                </a></li> -->

                <!-- Privacy policy-->  
                <router-link to="/front-portal/privacy-policy" @click="closeModal">
                  <li>
                      <div class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-gray-100 dark:hover:bg-neutral-700 dark:text-neutral-400 dark:hover:text-neutral-300">
                      Privacy Policy
                      </div>
                  </li>
                </router-link> 

                <!-- Tems of use-->  
                <router-link to="/front-portal/terms-of-use" @click="closeModal">
                  <li>
                      <div class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-gray-100 dark:hover:bg-neutral-700 dark:text-neutral-400 dark:hover:text-neutral-300">
                      Terms of use
                      </div>
                  </li>
                </router-link> 

                <!-- Logout button -->
                <button v-if="auth.isLoggedIn" @click="auth.logout()" class="py-3 w-[43%] bg-black text-sm text-white rounded-md cursor-pointer hover:bg-gray-400 block ml-2 mt-2">
                    Log out
                </button>

              </ul>
            </div>
          </li>

        </ul>
      </nav>
    </div>
    <!-- End Sidebar -->
 </div>   

</template>

<script setup>
import {onMounted, inject, ref,computed,defineEmits} from 'vue';
import { HSOverlay } from 'preline' // Adjust import path as needed


const auth = inject('$auth')
const emit = defineEmits(['show-role-switch-confirmation-modal']);


const props = defineProps({
  userDetails: {
      type: Object,
      required: true,
    },
  currentRole: {
      type: String,
      required: true,
    },
  });

const closeModal = () => {
  console.log("close modal called")
  const { element } = HSOverlay.getInstance('#hs-offcanvas-example-admin', true);
  
  if (element) {
    element.close();

  }
}
const handleRoleSwitchConfirmationModal= () =>{
    emit('show-role-switch-confirmation-modal'); 
    closeModal();
  }

// const dashboardPath = computed( ()=>{
//       if (this.userDetails.is_system_manager || this.userDetails.user_roles.includes('Educator')) {
//         return '/front-portal/educator-dashboard';
//       } else if (this.userDetails.user_roles.includes('Parent')) {
//         return '/front-portal/parent-dashboard';
//       } else {
//         return '/';  // Fallback path if no specific role matches
//       }
//     })


// to display right role in the switch role button text
const nextRole = computed(() => {
  if (props.currentRole === 'Parent') {
    // Prioritized order of roles to switch back to
    const priorityRoles = ['Guest Facilitator','System Manager', 'Educator',];
    
    // Find the highest priority role that the user has
    for (const role of priorityRoles) {
       if (props.userDetails.user_roles.includes(role)) {
        return role;
      }
    }
    // If no other role found, default to 'Guest Facilitator'
    return 'Guest Facilitator';
  } else {
    // If current role is not 'Parent', switch to 'Parent'
    return 'Parent';
  }
});    

onMounted(() => {
  setTimeout(() => {
        window.HSStaticMethods.autoInit();
      }, 100) 
 })
  
    

</script>